import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Link,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/css';
import * as React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { LogoSubTitle } from '../logo/Logo';

const styles = {
  container: css({
    backgroundColor: '#000',
    paddingTop: 20,
    paddingBottom: 20,
  }),
};

export const TopNavBar = () => {
  const showDesktopNav = useBreakpointValue({ base: false, md: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<any>();
  return (
    <div className={styles.container}>
      <Container maxW="container.xl">
        <Flex
          gap="20"
          justifyContent="center"
          alignItems={['start', 'center']}
          fontSize="x-large"
          flexDirection={['row']}>
          {showDesktopNav ? (
            <MenuLinks />
          ) : (
            <Flex gap="20" width={'100%'}>
              <Box flex="1">
                <LogoSubTitle />
              </Box>
              <IconButton
                flex="0"
                variant="outline"
                colorScheme="white"
                aria-label="Call Sage"
                fontSize="20px"
                icon={<GiHamburgerMenu />}
                ref={btnRef}
                onClick={onOpen}
              />
            </Flex>
          )}
        </Flex>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef as any}>
          <DrawerOverlay />
          <DrawerContent style={{ backgroundColor: '#0361ff' }}>
            <DrawerCloseButton />
            <DrawerHeader>$CAMP</DrawerHeader>
            <DrawerBody>
              <MenuLinks />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </div>
  );
};

const MenuLinks = () => {
  return (
    <Flex
      gap={[0, 0, 5, 20]}
      justifyContent="center"
      alignItems={['end', 'end', 'center']}
      fontSize="x-large"
      flexDirection={['column', 'column', 'row']}
      flexWrap={'wrap'}>
      <Link href="#about">ABOUT.</Link>
      <Link href="https://dexscreener.com/base/0xcFbc8551052a88E8Bd2dEd7FbC142160C6D26246">
        CHART.
      </Link>
      <Link href="https://t.me/basedcamp_official" isExternal>
        COMMUNITY.
      </Link>
      <Link href="#tokenomics">TOKENOMICS.</Link>
      <Link
        href="https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x78FdD546F78D1DeA8D265674489fF9fdDaFc0dbD"
        color={['#fff', '#0361ff']}>
        BUY on Sushiswap.
      </Link>
    </Flex>
  );
};
