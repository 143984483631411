import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'kindergartenmedium';
        src: url('/kindergarten-webfont.woff2') format('woff2'),
             url('/kindergarten-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }`}
  />
);

export default Fonts;
