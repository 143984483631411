import * as React from 'react';
import { Hero } from '../../components/hero/Hero';
import { CallToActionBar } from '../../components/call-to-action-bar/CallToActionBar';
import { MainContent } from '../../components/main-content/MainContent';

export const Home = () => {
  return (
    <>
      <Hero />
      <CallToActionBar />
      <MainContent />
    </>
  );
};
