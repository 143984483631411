import { Box, Button, Container, Flex, Icon, Text } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { useToast } from '@chakra-ui/react';
import { mq } from '../../utils/emotion-breakpoints';
const contractString = '0x78FdD546F78D1DeA8D265674489fF9fdDaFc0dbD';

const styles = {
  container: css({
    backgroundColor: '#0361ff',
    paddingTop: 10,
    paddingBottom: 10,
    [mq[0]]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  }),
  contractText: css({
    overflow: 'hidden',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100vw',
  }),
};

export const CallToActionBar = () => {
  // Hooks
  const toast = useToast();

  const copyContract = () => {
    navigator.clipboard.writeText(contractString).then(
      () => {
        toast({
          title: 'Copied',
          description: 'Contract address copied to clipboard!',
          status: 'success',
          duration: 3000,
          isClosable: false,
        });
      },
      () => {
        toast({
          title: 'Error',
          description: 'Failed to copy contract address to clipboard',
          status: 'error',
          duration: 3000,
          isClosable: false,
        });
      },
    );
  };
  const openSushiSwap = () => {
    window.open(
      'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x78FdD546F78D1DeA8D265674489fF9fdDaFc0dbD',
      '_blank',
    );
  };
  return (
    <Flex color="white" className={styles.container}>
      <Container maxW="container.xl">
        <Flex
          gap={[3, 10]}
          alignItems="center"
          flexDirection={['column', 'row']}>
          <Flex
            flex="1"
            gap={[1, 5]}
            alignItems="center"
            flexDirection={['column', 'row']}>
            <Box>
              <Text fontWeight={700} fontSize="x-large">
                CONTRACT:
              </Text>
            </Box>
            <Box>
              <Text
                fontSize="x-large"
                fontFamily={'monospace'}
                onClick={copyContract}
                paddingRight={['5', '0']}
                paddingLeft={['5', '0']}
                className={styles.contractText}>
                {contractString}
              </Text>
            </Box>
          </Flex>
          <Box flex="0">
            <Button
              borderRadius={0}
              color={'#000'}
              backgroundColor={'#FFF'}
              _hover={{ bg: '#000', color: '#FFF' }}
              onClick={openSushiSwap}
              leftIcon={
                <Icon viewBox="0 0 990 916" width="6" height="6">
                  <path
                    fill="url(#paint0_linear_12919_18033)"
                    fill-rule="evenodd"
                    d="M969.311 593.493l-193.11 269.99c-28.12 39.37-82.96 56.71-152.8 51.09-97.03-8.44-225.93-60-349.2-148.58a975.047 975.047 0 01-113.6-94.36c-65.32-63.44-113.33-129.44-139.04-188.29-28.12-64.68-29.05-121.86-.93-161.23L214.2 52.123c28.13-39.37 82.5-56.71 152.81-51.09 97.03 7.97 225.46 60 349.19 148.12 123.28 88.59 213.75 194.05 252.18 283.11 3.32 7.64 6.26 15.17 8.82 22.59 19.11 55.35 16.91 103.92-7.89 138.64z"
                    clip-rule="evenodd"></path>
                  <path
                    fill="#fff"
                    fill-rule="evenodd"
                    d="M938.381 445.383c-37.03-84.84-123.75-184.68-241.86-269.05-117.65-84.37-239.98-134.52-332.32-142.02-56.25-4.69-100.78 5.63-123.27 37.03l-.95 1.87c-21.09 31.41-16.4 75.94 5.63 126.56 37.03 85.31 123.74 185.14 241.4 269.51 117.64 84.371 239.98 134.53 332.31 142.03 55.31 4.21 98.91-5.629 121.87-35.159l1.41-2.341c22.5-30.94 17.81-76.87-4.22-128.43zm-172.96 1.88c-10.31 14.52-31.4 18.75-57.18 16.4-46.41-3.75-107.34-29.06-166.4-71.24-59.06-42.19-102.65-91.88-120.93-134.53-10.31-23.9-13.12-44.99-2.81-59.53 10.32-14.53 31.41-18.75 57.65-16.87 45.93 4.22 107.34 29.06 165.93 71.25 59.06 42.18 102.65 92.33 120.93 134.99 10.78 23.9 13.59 44.99 2.81 59.53z"
                    clip-rule="evenodd"></path>
                  <defs>
                    <linearGradient
                      id="paint0_linear_12919_18033"
                      x1="336.076"
                      x2="653.893"
                      y1="-11.067"
                      y2="926.765"
                      gradientUnits="userSpaceOnUse">
                      <stop stopColor="#27B0E6"></stop>
                      <stop offset="0.107" stopColor="#49A1DB"></stop>
                      <stop offset="0.288" stopColor="#7D8ACA"></stop>
                      <stop offset="0.445" stopColor="#A279BD"></stop>
                      <stop offset="0.572" stopColor="#BA6FB6"></stop>
                      <stop offset="0.651" stopColor="#C26BB3"></stop>
                      <stop offset="0.678" stopColor="#D563AD"></stop>
                      <stop offset="0.715" stopColor="#E65BA7"></stop>
                      <stop offset="0.76" stopColor="#F156A3"></stop>
                      <stop offset="0.824" stopColor="#F853A1"></stop>
                      <stop offset="1" stopColor="#FA52A0"></stop>
                    </linearGradient>
                  </defs>
                </Icon>
              }
              colorScheme="blue"
              size="lg">
              BUY $CAMP HERE
            </Button>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};
