import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { css } from '@emotion/css';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Logo } from '../logo/Logo';
import { mq } from '../../utils/emotion-breakpoints';

export const styles = {
  hero: css({
    height: 1000,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    [mq[0]]: {
      height: '92vh',
    },
  }),
  container: css({
    position: 'relative',
    zIndex: 2,
  }),
  video: css({
    zIndex: 1,
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    minWidth: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    pointerEvents: 'none',
  }),
};

export const Hero = () => {
  return (
    <div className={styles.hero}>
      <Video />
      <Container className={styles.container} height="100%" maxW="container.xl">
        <Flex
          color="white"
          gap={[5, 10]}
          paddingTop={[10, 100]}
          height="100%"
          flexDirection={['column', 'row']}>
          <Box flex={['0', '0']}>
            <Logo showSubtitle />
          </Box>
          <Intro />
        </Flex>
      </Container>
    </div>
  );
};

export const Video = () => {
  return (
    <video className={styles.video} autoPlay muted loop>
      <source src="video-basedcamp_compressed.webm" type="video/mp4" />
    </video>
  );
};
export const Intro = () => {
  return (
    <Flex flex="1" flexDirection={'column'} height={'100%'}>
      <Text fontSize="2xl" textAlign={['center', 'left']}>
        Welcome to the...
      </Text>
      <Flex
        alignItems={['center', 'start', 'start']}
        gap={[5, 5, 10]}
        flexDirection={['column', 'column', 'row']}
        marginBottom={[0, 10, 0]}>
        <Heading
          flex="1"
          as="h1"
          size="4xl"
          marginBottom={[0, 0, 3]}
          marginTop={3}>
          basedcamp.
        </Heading>
        <SocialLinks />
      </Flex>

      <Text
        fontSize={['large', '2xl']}
        marginTop={['auto', 0]}
        backgroundColor={['rgba(0, 0, 0, 0.75)', 'transparent']}
        borderRadius={['10px', '0']}
        padding={['10px', '0']}
        marginBottom={[5, '0']}
        textAlign={['center', 'left']}
        alignSelf={['end', 'auto']}>
        your expedition starts (and ends) here.
        <br />
        Stay at basedcamp. or come back to basedcamp.
        <br />
        <br />
        touch grass, take a seat at basedcamp.
      </Text>
    </Flex>
  );
};
export const SocialLinks = () => {
  const openTelegram = () => {
    window.open('https://t.me/basedcamp_official', '_blank');
  };
  const openTwitter = () => {
    window.open('https://twitter.com/basedcamp_0x', '_blank');
  };
  const openDexScreener = () => {
    window.open(
      'https://dexscreener.com/base/0xcFbc8551052a88E8Bd2dEd7FbC142160C6D26246',
      '_blank',
    );
  };
  const openSomeOtherShit = () => {
    window.open(
      'https://www.dextools.io/app/en/base/pair-explorer/0xcfbc8551052a88e8bd2ded7fbc142160c6d26246?t=1711424612868',
      '_blank',
    );
  };
  const openSomeNewShit = () => {
    window.open('https://warpcast.com/basedcamp', '_blank');
  };
  const openGeckoTerminal = () => {
    window.open(
      'https://www.geckoterminal.com/base/pools/0xcfbc8551052a88e8bd2ded7fbc142160c6d26246',
      '_blank',
    );
  };
  return (
    <Flex flex="0" alignItems="center" gap="3">
      <IconButton
        isRound={true}
        variant="solid"
        backgroundColor="#0361ff"
        color="#fff"
        aria-label="Done"
        fontSize="20px"
        size="lg"
        icon={<FaTelegramPlane />}
        onClick={openTelegram}
        _hover={{ bg: '#0361ff', color: '#fff', opacity: 0.75 }}
      />
      <IconButton
        isRound={true}
        variant="solid"
        color="#fff"
        backgroundColor="#0361ff"
        aria-label="Done"
        fontSize="20px"
        size="lg"
        onClick={openTwitter}
        icon={<FaXTwitter />}
        _hover={{ bg: '#0361ff', color: '#fff', opacity: 0.75 }}
      />
      <IconButton
        isRound={true}
        variant="solid"
        aria-label="Done"
        backgroundColor="#0361ff"
        fontSize="20px"
        size="lg"
        onClick={openDexScreener}
        icon={
          <Icon viewBox="0 0 200 200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="#fff"
              fill-rule="evenodd"
              viewBox="0 0 252 300"
              focusable="false">
              <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
              <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
            </svg>
          </Icon>
        }
        _hover={{ bg: '#0361ff', color: '#000', opacity: 0.75 }}
      />
      <IconButton
        isRound={true}
        variant="solid"
        aria-label="Done"
        backgroundColor="#0361ff"
        fontSize="20px"
        size="lg"
        onClick={openSomeOtherShit}
        icon={<Image src="/icon-dextools.png" width={6} />}
        _hover={{ bg: '#0361ff', color: '#000', opacity: 0.75 }}
      />
      <IconButton
        isRound={true}
        variant="solid"
        aria-label="Done"
        backgroundColor="#111827"
        fontSize="20px"
        size="lg"
        onClick={openGeckoTerminal}
        icon={<Image src="/gecko-terminal.png" width={6} />}
      />
      <IconButton
        isRound={true}
        variant="solid"
        aria-label="Done"
        backgroundColor="#472A91"
        fontSize="20px"
        size="lg"
        onClick={openSomeNewShit}
        icon={
          <Icon viewBox="0 0 1260 1260" width="10" height="10">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M947.747 1259.61H311.861C139.901 1259.61 0 1119.72 0 947.752V311.871C0 139.907 139.901 0.00541362 311.861 0.00541362H947.747C1119.71 0.00541362 1259.61 139.907 1259.61 311.871V947.752C1259.61 1119.72 1119.71 1259.61 947.747 1259.61Z"
                  fill="#472A91"></path>
                <path
                  d="M826.513 398.633L764.404 631.889L702.093 398.633H558.697L495.789 633.607L433.087 398.633H269.764L421.528 914.36H562.431L629.807 674.876L697.181 914.36H838.388L989.819 398.633H826.513Z"
                  fill="white"></path>
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect width="1259.61" height="1259.61" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </Icon>
        }
        _hover={{ bg: '#472A91', color: '#000', opacity: 0.75 }}
      />
    </Flex>
  );
};
