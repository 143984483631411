import * as react from 'react';
import { TopNavBar } from '../top-navbar/TopNavBar';
import { Footer } from '../footer/Footer';
import { Box, Container, Text } from '@chakra-ui/react';

const BewareOfScammers = () => {
  return (
    <Box background="red" paddingTop={2} paddingBottom={2}>
      <Container maxW="container.xl">
        <Text textAlign="center" fontSize="large">
          Please be very careful of impersonators of our project scamming. We
          are the original creators of this concept and project. This is the
          official website and only any social streams linked from here are
          official.
        </Text>
      </Container>
    </Box>
  );
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <TopNavBar />
      {children}
      <Footer />
    </>
  );
};
