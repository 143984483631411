import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { css } from '@emotion/css';
import * as React from 'react';
import { mq } from '../../utils/emotion-breakpoints';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: 1300,
    backgroundImage: 'url(/bg-wagmi.jpg)',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#0b1731',
    [mq[0]]: {
      height: 600,
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
    },
  }),
  title: css({
    span: {
      color: '#0361ff',
    },
  }),
  girthyLogo: css({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    userSelect: 'none',
    textAlign: 'center',
    span: {
      color: '#aebd5c',
    },
  }),
  extraText: css({
    backgroundColor: '#0b1731',
  }),
};

export const Footer = () => {
  const openSomeShit = () => {
    window.open(
      'https://www.team.finance/view-coin/0x78FdD546F78D1DeA8D265674489fF9fdDaFc0dbD?name=basedcamp&symbol=CAMP&chainid=0x2105',
      '_blank',
    );
  };
  return (
    <div>
      <div id="tokenomics" className={styles.container}></div>
      <div className={styles.extraText}>
        <Container maxW="container.sm">
          <Box alignSelf="center" marginBottom={[20, 60]}>
            <Text
              fontSize="xxx-large"
              className={styles.title}
              textAlign={['center']}
              marginBottom={[10]}>
              <span>$CAMP</span> TOKENOMICS
            </Text>
            <Text fontSize="x-large" textAlign={['center']}>
              1/1 tax
              <br />
              (buybacks, lp injections, lp burns)
              <br />
              team tokens locked
              <br />
              liquidity locked
            </Text>
            <Flex alignItems="center" justifyContent="center" marginTop={10}>
              <Button onClick={openSomeShit}>Lock Details</Button>
            </Flex>
          </Box>
          <GirthyGreenLogo />
          <EmailInfo />
          <Copyright />
        </Container>
        <Container maxW="container.xl">
          <Text
            fontSize="small"
            display="block"
            marginTop={20}
            paddingBottom={5}
            color="rgba(255, 255, 255, .3)">
            DISCLOSURE By purchasing CAMP token, you agree that you are not
            purchasing a security or investment contract and you agree to hold
            the team harmless and not liable for any losses or taxes you may
            incur. Although Basedcamp is a community-driven DeFi token and not a
            registered digital currency, always make sure that you are in
            compliance with local laws and regulations before you make any
            purchase.
          </Text>
        </Container>
      </div>
    </div>
  );
};

export const EmailInfo = () => {
  return (
    <Flex alignContent="center" justifyContent="center" marginTop={5}>
      Contact:&nbsp;
      <Link href="mailto:basedcamponbase@protonmail.com" target="_blank">
        basedcamponbase@protonmail.com
      </Link>
    </Flex>
  );
};

export const Copyright = () => {
  return (
    <Flex alignContent="center" justifyContent="center" marginTop={5}>
      basedcamp &copy; 2024
    </Flex>
  );
};

export const GirthyGreenLogo = () => {
  const openTelegram = () => {
    window.open('https://t.me/girthygreens', '_blank');
  };
  return (
    <div className={styles.girthyLogo} onClick={openTelegram}>
      <Image src="/logo-girthygreens.png" alt="Girthy Green Logo" />
      <Text>
        Project developed <br /> by <span>GIRTHY GREENS</span>
      </Text>
    </div>
  );
};
