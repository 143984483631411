import { ChakraProvider } from '@chakra-ui/react';
import { Layout } from './components/layout/Layout';
import { Home } from './features/home/Home';
import Fonts from './lib/chakra/Fonts';
import theme from './lib/chakra/theme';

function App() {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Fonts />
      <Layout>
        <Home />
      </Layout>
    </ChakraProvider>
  );
}

export default App;
