import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        color: '#fff',
      },
      a: {
        color: '#fff',
      }
    }
  },
  fonts: {
    heading: `'kindergartenmedium', sans-serif`,
    body: `'kindergartenmedium', sans-serif`,
  },
})

export default theme