import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { mq } from '../../utils/emotion-breakpoints';

const styles = {
  container: css({
    backgroundImage: 'url(/bg-tiles.jpg)',
    width: '100%',
    paddingBottom: 100,
    paddingTop: 100,
    backgroundRepeat: 'repeat',
    [mq[0]]: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  }),
  lastImage: css({
    display: 'block',
    margin: '0 auto',
    height: 300,
    backgroundPosition: 'center',
    backgroundImage: 'url(/stupid-goat.png)',
    backgroundSize: 'cover',
    [mq[0]]: {
      backgroundSize: 'initial',
    },
  }),
  section: css({
    marginBottom: 100,
    [mq[0]]: {
      marginBottom: 50,
    },
    span: {
      color: 'red',
    },
  }),
};

export const MainContent = () => {
  return (
    <div id="about" className={styles.container}>
      <Container maxW="container.xl">
        <Section1 />
        <Section2 />
        <Section3 />
        <div className={styles.lastImage} />
        <Box backgroundColor={'#000'} padding={4}>
          <Text color="#fff" fontSize={'x-large'}>
            psst bro... ever just chill at the basedcamp?
          </Text>
        </Box>
      </Container>
    </div>
  );
};

export const Section1 = () => {
  return (
    <Flex
      className={styles.section}
      alignItems="flex-start"
      gap={['10', '20']}
      flexDirection={['column-reverse', 'row']}>
      <Box flex="1">
        <Text fontSize={['2xl', '4xl']}>
          in the world of investments, where fortunes are made and lost, many a
          degen dares to climb the highest peaks in search of riches...
        </Text>
      </Box>
      <Image
        src="/storyboard-01.png"
        alt="Psst bro... ever just chill at the Basedcamp?"
      />
    </Flex>
  );
};

export const Section2 = () => {
  return (
    <Flex
      className={styles.section}
      alignItems="center"
      gap={['10', '20']}
      flexDirection={['column', 'row']}>
      <Image
        src="/storyboard-02.png"
        alt="Psst bro... ever just chill at the Basedcamp?"
      />
      <Box flex="1">
        <Text fontSize={['2xl', '4xl']}>
          yet; ignoring the advice of wise boomers, we continue, only to slip
          and fall.
          <br />
          <br />
          “why does this keep happening?” we ask.
          <br />
          <br />
          “where is my lambo?”
        </Text>
      </Box>
    </Flex>
  );
};

export const Section3 = () => {
  return (
    <Flex
      className={styles.section}
      alignItems="center"
      gap={['10', '20']}
      flexDirection={['column-reverse', 'row']}>
      <Box flex="1">
        <Text fontSize={['2xl', '4xl']}>
          we climb the slopes of Mount Pepe, we trudge through the slimes of
          UnvaxxedSperm Valley.
          <br />
          <br />
          but to no avail.
          <br />
          <br />
          “there must be a better way...” we sigh.
        </Text>
      </Box>
      <Image
        src="/storyboard-03.png"
        alt="Psst bro... ever just chill at the Basedcamp?"
      />
    </Flex>
  );
};
