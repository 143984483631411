import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { css } from '@emotion/css';
import * as React from 'react';

export const styles = {
  logo: css({
    display: 'block',
    width: 172,
    height: 172,
  }),
  subtitle: css({
    textAlign: 'center',
    span: {
      color: '#0361ff',
    },
  }),
};

export const Logo = ({ showSubtitle = false }) => {
  const mobileSubTitle = useBreakpointValue({ base: false, md: true });

  return (
    <Flex flexDirection={['column']} align={['center']}>
      <Image
        src="/logo-basedcamp.png"
        alt="$CAMP on BASE"
        width={172}
        height={172}
        minWidth={172}
        minHeight={172}
        className={styles.logo}
      />
      {showSubtitle && mobileSubTitle && <LogoSubTitle />}
    </Flex>
  );
};

export const LogoSubTitle = () => {
  return (
    <Flex
      flexDirection={['row', 'row', 'column']}
      marginTop={[0, 0, 2]}
      alignItems={['center', 'center', 'start']}
      className={styles.subtitle}>
      <Text fontSize={'x-large'}>
        <span>$CAMP</span> on
      </Text>
      <Box alignSelf="center" marginTop={[0, 0, 1]} marginLeft={[5, 5, 0]}>
        <Image src="/logo-base-network.png" alt="Base Network" />
      </Box>
    </Flex>
  );
};
